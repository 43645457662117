import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({ description, lang, meta, title, image, siteMetadata }) {

    const metaDescription = description || siteMetadata.description
    const defaultTitle = siteMetadata?.title

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `robots`,
                    content: "index, follow",
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: image || siteMetadata?.preview_image_url,
                },
                {
                    property: `og:image:alt`,
                    content: `Darwin Academy, Mirza | Junior College, Mirza, Kamrup (R) Assam.`
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:site_name`,
                    content: defaultTitle,
                },
                {
                    property: `og:url`,
                    content: `https://darwinacademymirza.com`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: image || siteMetadata?.preview_image_url,
                },
                {
                    property: `fb:pages`,
                    content: siteMetadata?.facebook_page,
                }
            ].concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    image: null
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    siteMetadata: PropTypes.object.isRequired
}

export default Seo
