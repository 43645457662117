import React from 'react';
import Footer from './Footer';
import Header from './Header';
const Layout = (props) => {
    //const { siteData, contactData } = props;
    return (
        <>
            <Header {...props} />
            {props.children}
            <Footer {...props} />
        </>
    );
}

export default Layout;