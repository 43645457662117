import React from "react"
const Footer = ({ siteData, contactData }) => {
  return (
    <>
      <footer className="footer-area black-bg-color" style={{ paddingTop: 80 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <div
                className="single-footer-widget bg-f9f5f1"
                style={{ textAlign: "center", paddingTop: 0 }}
              >
                <a href="index.html" className="logo">
                  <img
                    src="/images/logo_dark.svg"
                    style={{ width: 200, height: 200 }}
                    alt="Image"
                  />
                </a>

                <ul className="social-icon">
                  <li>
                    <a
                      href="https://www.facebook.com/Darwin-Academy-Mirza-101768878870217"
                      target="_blank"
                    >
                      <i className="ri-facebook-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/darwinacademymirza/"
                      target="_blank"
                    >
                      <i className="ri-instagram-line"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCGHTMjLPaUks-8OYMJaujqw"
                      target="_blank"
                    >
                      <i className="ri-youtube-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="ri-twitter-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="single-footer-widget">
                <h3>Quick Access</h3>
                <ul className="import-link">
                  <li>
                    <a href="/courses">Application</a>
                  </li>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/about">About the Academy</a>
                  </li>
                  <li>
                    <a href="/faculties">Faculties</a>
                  </li>
                  <li>
                    <a href="/esdf">
                      Education, Skill &amp; Development Foundation
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="single-footer-widget">
                <h3>Study</h3>
                <ul className="import-link">
                  <li>
                    <a href="/noticeboard">News and Events</a>
                  </li>
                  <li>
                    <a href="/courses">Courses</a>
                  </li>
                  <li>
                    <a href="/courses">Fee Structure</a>
                  </li>
                  <li>
                    <a href="/rules">College Uniform</a>
                  </li>
                  <li>
                    <a href="/rules">Rules and Regulations</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="single-footer-widget">
                <h3>Inquiries</h3>
                <ul className="address">
                  <li className="location">
                    <i className="ri-map-pin-line"></i>
                    <span>Address</span>
                    {contactData.fullAddress}
                  </li>
                  <li>
                    <i className="ri-mail-line"></i>
                    <span>Email</span>
                    <span>
                      {siteData.email ||
                        contactData.email ||
                        "principal@darwinacademymirza.com"}
                    </span>
                  </li>
                  <li>
                    <i className="ri-phone-line"></i>
                    <span>Phone</span>
                    <a href={`tel:${contactData.phone}`}>{contactData.phone}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy-right-area">
        <div className="container">
          <p>
            Copyright <i className="ri-copyright-line"></i> 2021{" "}
            {siteData.title_short}. Design &amp; Developed By &nbsp;
            <a href="https://itechcom.net/" target="_blank">
              ITECHCOM - I. Tech Computer{" "}
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
