import React from "react"
import Layout from "./Layout"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"


const ContactData = {
  shortAddress:
    "Near Green Orchid Group of Companies, Barkuchi, Mirza - 781125, Kamrup (R), Assam.",
  fullAddress:
    "Near Green Orchid Group of Companies, Barkuchi, Mirza - 781125, Assam.",
  email: "principal@darwinacademymirza.com",
  phone: "+91 87230 75048",
  phone_secondary: "+91 98641 27383, +91 98641 37608",
}

const GlobalStyles = createGlobalStyle`
  @media only screen and (max-width: 991px) {
    nav.mean-nav {
      margin-top: 80px !important;
    }
  }
`

export default function withLayout(Component) {
  return props => {
    const data = useStaticQuery(graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            company_name
            title
            emergency_contact
            description
            site_url
            title_short
            title
            author
            logo_image_url
            preview_image_url
            facebook_page
            prospectus_link
            application_form_link
          }
        }
      }
    `)

    return (
      <>
        <GlobalStyles />
        <Layout siteData={data.site.siteMetadata} contactData={ContactData}>
          <Component
            {...props}
            siteData={data.site.siteMetadata}
            contactData={ContactData}
          />
        </Layout>
      </>
    )
  }
}
