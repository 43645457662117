import React, { useEffect } from "react"
import styled from "styled-components"

const BrandText = styled.div`
  p.main-brand {
    color: #14335b;
    margin: 0;
    margin-top: 8px;
    padding: 0;
    font-size: 30px;
    font-family: "Libre Baskerville";
    font-weight: bold;
    line-height: 0;

    span {
      color: #648ac7;
    }
  }

  p.brand-slogan {
    margin: 0;
    margin-top: 30px;
    line-height: 0;
    padding: 0;
    font-size: 13px;
    color: #648ac7;
    letter-spacing: "2px";
    text-transform: uppercase;
  }
`

const Header = ({ siteData, contactData }) => {
  useEffect(() => {
    const { $ } = window
    $(".mean-menu").meanmenu({ meanScreenWidth: "991" })
  }, [])
  return (
    <header className="header-area">
      <div className="top-header" style={{ fontSize: 12, padding: 8 }}>
        <div className="container" style={{ maxWidth: 1300 }}>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6">
              {/* <ul className="header-left-content">
                                <li>
                                    <a href="tel:+1-(514)-312-5678">
                                        <i className="ri-phone-fill"></i>
                                        {siteData.emergency_contact}
                                    </a>
                                </li>
                            </ul> */}
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="header-right-content d-flex align-items-center">
                <div>
                  <a href="/" style={{ fontSize: 13, color: "#fff" }}>
                    Home
                  </a>{" "}
                  &nbsp; | &nbsp;
                  <a href="/courses" style={{ fontSize: 13, color: "#fff" }}>
                    Courses
                  </a>{" "}
                  &nbsp; | &nbsp;
                  <a href="/esdf" style={{ fontSize: 13, color: "#fff" }}>
                    ESDF
                  </a>{" "}
                  &nbsp; | &nbsp;
                  <a href="/management" style={{ fontSize: 13, color: "#fff" }}>
                    Sign In
                  </a>
                </div>

                {/* <div
                  className="my-account"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a href="/" style={{ marginRight: 10 }}>
                    <i className="ri-user-fill"></i>
                  </a>
                  <span style={{ fontSize: 13, color: "#fff" }}>Account</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-area">
        {/* Mobile menu */}
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo" style={{ width: "auto", maxWidth: 300 }}>
                <a href="#" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/logo.svg"
                    alt="logo"
                    style={{ height: 50 }}
                  />
                  <span
                    style={{
                      fontFamily: "var(--heading-font-family)",
                      marginLeft: 10,
                      fontSize: 20,
                      color: "#14335B",
                    }}
                  >
                    Darwin Academy,
                    <br /> Mirza
                  </span>
                </a>
              </div>
              <div className="others-options-for-mobile-devices"></div>
            </div>
          </div>
        </div>

        {/* End Mobile Menu */}

        <div className="desktop-nav">
          <div className="container" style={{ maxWidth: 1300 }}>
            <nav className="navbar navbar-expand-md navbar-light">
              <div>
                <div
                  className="navbar-brand"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src="/images/logo.svg"
                    alt="logo"
                    style={{
                      height: 70,
                      width: 70,
                      marginRight: 15,
                      marginTop: -3,
                    }}
                  />

                  <BrandText>
                    <p className="main-brand">
                      Darwin Academy, Mirza
                    </p>
                    <p className="brand-slogan">{contactData.shortAddress}</p>
                  </BrandText>
                </div>
              </div>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
                style={{
                  justifyContent: "space-between !important",
                  width: "100%",
                }}
              >
                <ul
                  className="navbar-nav"
                  style={{ justifyContent: "flex-start" }}
                >
                  {/* <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            Home
                                        </a>
                                    </li> */}
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Academics
                      <i className="ri-arrow-down-s-line"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="/courses" className="nav-link">
                          Courses and Fee Structure
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/faculties" className="nav-link">
                          Faculties
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/rules" className="nav-link">
                          Rules and Regulations
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/career_councelling" className="nav-link">
                          Career Councelling Cell
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="/about" className="nav-link">
                      About Us
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="/noticeboard" className="nav-link">
                      Noticeboard
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/faq" className="nav-link">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact" className="nav-link">
                      Contact Us
                    </a>
                  </li>
                </ul>
                {/* <div className="others-options">
                                    <ul>
                                        <li>
                                            <a href="application.html" className="default-btn">
                                                Application form
                                            </a>
                                        </li>
                                        <li>
                                            <button type="button" className="search-btn" data-bs-toggle="modal" data-bs-target="#exampleModalsrc">
                                                <i className="ri-search-line"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

/*
<div className="logo" style={{ width: "auto" }}>
                <a href="#" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/logo.svg"
                    alt="logo"
                    style={{ height: 50 }}
                  />
                  <span
                    style={{
                      fontFamily: "var(--heading-font-family)",
                      marginLeft: 10,
                      fontSize: 20,
                      color: "#14335B",
                    }}
                  >
                    Darwin Academy,
                    <br /> Mirza
                  </span>
                </a>
              </div>
*/
